const ramadhanDatesConfig = {
  '2014': {
    from: '29/06/2014',
    to: '28/07/2014'
  },
  '2015': {
    from: '18/06/2015',
    to: '17/07/2015'
  },
  '2016': {
    from: '06/06/2016',
    to: '06/07/2016'
  },
  '2017': {
    from: '27/05/2017',
    to: '25/06/2017'
  },
  '2018': {
    from: '17/05/2018',
    to: '15/06/2018'
  },
  '2019': {
    from: '06/05/2019',
    to: '05/06/2019'
  },
  '2020': {
    from: '24/04/2020',
    to: '24/05/2020'
  },
  '2021': {
    from: '13/04/2021',
    to: '13/05/2021'
  },
  '2022': {
    from: '03/04/2022',
    to: '28/04/2022'
  },
  '2023': {
    from: '23/03/2023',
    to: '21/04/2023'
  },
  '2024': {
    from: '11/03/2024',
    to: '10/04/2024'
  }
}

export default ramadhanDatesConfig
