<!-- =========================================================================================
File Name: CardAnalytics.vue
Description: Analytic Cards
----------------------------------------------------------------------------------------
Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div v-show="loaded">
    <div class="vx-row">
      <!-- Upload Database Absent -->
      <div
        class="vx-col mb-base w-full md:w-1/2"
        v-if="user.employee.id_jabatan == 4"
      >
        <vx-card
          title="Upload database absent"
          subtitle="Only SQL file"
          style="height: 100%"
        >
          <form class="vx-row" @submit.prevent="handleFile">
            <div class="vx-col w-full md:w-2/3">
              <input
                type="hidden"
                name="redirect"
                :value="redirect"
                style="width: 100%"
              />
              <input type="file" name="sql" style="width: 100%" ref="sqlFile" />
            </div>
            <div class="vx-col w-full md:w-1/3">
              <button
                class="
                  vs-component vs-button vs-button-primary vs-button-filled
                  includeIcon
                "
                type="submit"
              >
                Upload
              </button>
              <!--              <button class="vs-component vs-button vs-button-primary vs-button-filled includeIcon" type="submit" disabled v-else>Uploading..</button>-->
            </div>
          </form>
        </vx-card>
      </div>

      <!--      &lt;!&ndash; Upload Database Absent &ndash;&gt;-->
      <!--      <div class="vx-col w-full md:w-1/2" v-if="user.employee.id_jabatan == 4">-->
      <!--        <vx-card title="Upload database absent" subtitle="Only SQL file" style="height: 100%">-->
      <!--          <div class="vx-row">-->
      <!--              <div class="vx-col w-full md:w-1/1">-->
      <!--                <file-upload :url='url' :thumb-url='thumbUrl' :headers="headers" @change="onFileChange"></file-upload>-->
      <!--              </div>-->
      <!--          </div>-->
      <!--        </vx-card>-->
      <!--      </div>-->

      <!-- Position Filter -->
      <div
        class="vx-col mb-base w-full md:w-1/2"
        v-show="excep.includes(user.employee.nik) == true"
      >
        <vx-card title="Filter" style="height: 100%">
          <div class="vx-row">
            <div class="vx-col w-full md:w-1/1">
              <label class="text-sm opacity-75">Position</label>
              <v-select
                :options="positions"
                :clearable="false"
                v-model="position"
              ></v-select>
            </div>
          </div>
        </vx-card>
      </div>
    </div>

    <!-- All of Employee Punctuality -->
    <div class="vx-row" v-show="excep.includes(user.employee.nik) == true">
      <div class="vx-col w-full md:w-1/1 lg:w-1/1">
        <vx-card
          title="Jumlah ketepatan waktu datang pegawai"
          :subtitle="`All position from ${$convertDate(
            new Date(punctuality.filter.start_date),
            'DD-MM-YYYY'
          )} to ${$convertDate(
            new Date(punctuality.filter.end_date),
            'DD-MM-YYYY'
          )}`"
          class="mb-base"
        >
          <template slot="actions">
            <div class="vx-row">
              <div class="vx-col w-full md:w-6/12">
                <vs-input
                  type="date"
                  size="small"
                  :label="$i18n.t('StartDate')"
                  @change="getPunctualityChart(false)"
                  v-model="punctuality.filter.start_date"
                  class="w-full mr-2"
                ></vs-input>
              </div>
              <div class="vx-col w-full md:w-6/12">
                <vs-input
                  type="date"
                  size="small"
                  :label="$i18n.t('EndDate')"
                  @change="getPunctualityChart(false)"
                  v-model="punctuality.filter.end_date"
                  class="w-full mr-2"
                ></vs-input>
              </div>
            </div>
          </template>
          <div slot="no-body" class="p-6 pb-0">
            <!-- Chart -->
            <vue-apex-charts
              type="bar"
              height="266"
              :options="punctuality.options"
              :series="punctuality.series"
            />
          </div>
        </vx-card>
      </div>
    </div>

    <div
      class="vx-row mb-base"
      v-show="excep.includes(user.employee.nik) == true"
    >

      <!-- Absent Detail Employee -->
      <div class="vx-col w-full md:w-1/1">
        <vx-card
          :title="`Pilih Pegawai`"
          :subtitle="`Pilih pegawai untuk melihat absen`"
        >
          <template slot="actions">
            <v-select
              @input="employeeScans"
              :clearable="false"
              v-model="employee.year"
              :placeholder="$i18n.t('Year')"
              style="width: 100px"
              :options="years"
            ></v-select>
          </template>

          <div class="vx-row">
            <div
              :class="`vx-col w-full sm:w-1/5`"
              v-for="emp in employees.filter(
                (emp) => !notEmployees.includes(emp.nik)
              )"
              @click.prevent="detailEmployee(emp)"
              :key="emp.nik"
            >
              <div
                :class="`employee-item ${
                  employee.select
                    ? employee.select.nik == emp.nik
                      ? 'active'
                      : ''
                    : ''
                }`"
              >
                <vs-avatar
                  class="mx-auto my-6 block"
                  size="20px"
                  :src="$storageUser + '/' + emp.user.picture"
                  v-if="emp.user"
                />
                <vs-avatar
                  class="mx-auto my-6 block"
                  size="20px"
                  src="false"
                  v-else
                />

                <div style="white-space: nowrap; overflow: hidden">
                  {{ displayName(emp.name) }}
                </div>
              </div>
              <!--              <v-select @input="employeeScans" :clearable="false" v-model="employee.year" :placeholder="$i18n.t('Year')" :options="[2015, 2016, 2017, 2018, 2019, 2020, 2021].reverse()"></v-select>-->
            </div>
          </div>
        </vx-card>
      </div>
    </div>

    <div class="vx-row">
      <!-- Absent Detail Employee -->
      <div class="vx-col w-full md:w-2/3 mb-base">
        <vx-card
          style="height: 100%"
          :title="`Data absen tahun ${employee.select ? employee.year : '-'}`"
          :subtitle="`Pegawai: ${employee.select ? employee.select.name : '-'}`"
          class="mb-base"
        >
          <div v-if="load.scans">Loading...</div>
          <div v-else>
            <div v-if="employee.select">
              <vs-table max-items="6" pagination :data="employee.scans" search>
                <template slot="thead">
                  <vs-th>{{ $i18n.t("Day") }}</vs-th>
                  <vs-th>{{ $i18n.t("Date") }}</vs-th>
                  <vs-th>{{ $i18n.t("TimeCome") }}</vs-th>
                  <vs-th>{{ $i18n.t("TimeLeave") }}</vs-th>
                  <vs-th>{{ $i18n.t("Duration") }}</vs-th>
                </template>

                <template slot-scope="{ data }">
                  <vs-tr
                    :key="indextr"
                    v-for="(tr, indextr) in data"
                    :style="data[indextr].fri ? 'background: #eee;' : ''"
                  >
                    <vs-td :data="data[indextr].day">
                      {{ data[indextr].day }}
                    </vs-td>

                    <vs-td
                      :data="data[indextr].date"
                      style="letter-spacing: 1.2px"
                    >
                      {{ data[indextr].date }}
                    </vs-td>

                    <vs-td>
                      <div
                        @click="showDetailScan(data[indextr], 'time_come')"
                        v-if="data[indextr].chipCome != false"
                        style="margin-left: -5px"
                      >
                        <vs-chip
                          :color="data[indextr].chipCome"
                          style="cursor: pointer"
                        >
                          {{ data[indextr].time_come }}
                        </vs-chip>
                      </div>
                      <span style="letter-spacing: 1.2px" v-else>
                        {{ data[indextr].time_come }}
                      </span>
                    </vs-td>

                    <vs-td :data="data[indextr].time_leave">
                      <div
                        @click="showDetailScan(data[indextr], 'time_leave')"
                        v-if="data[indextr].chipLeave != false"
                        style="margin-left: -5px"
                      >
                        <vs-chip
                          :color="data[indextr].chipLeave"
                          style="cursor: pointer"
                        >
                          {{ data[indextr].time_leave }}
                        </vs-chip>
                      </div>
                      <span style="letter-spacing: 1.2px" v-else>
                        {{ data[indextr].time_leave }}
                      </span>
                    </vs-td>

                    <vs-td :data="data[indextr].duration" class="text-center">
                      {{ data[indextr].duration }}
                    </vs-td>
                  </vs-tr>
                </template>
              </vs-table>

              <div
                class="
                  flex
                  justify-between
                  mt-base
                  py-2
                  px-6
                  border
                  d-theme-border-grey-light
                  border-solid border-r-0 border-l-0 border-b-0
                "
              ></div>

              <h4>Export Data Absen</h4>
              <p class="mt-1 opacity-75">
                Pegawai : {{ employee.select ? employee.select.name : "-" }}
              </p>
              <div class="vx-row mt-2">
                <div class="vx-col w-full md:w-1/4">
                  <label class="text-sm opacity-75">Bulan</label>
                  <v-select
                    :options="months"
                    :clearable="false"
                    v-model="month"
                  ></v-select>
                </div>
                <div class="vx-col w-full md:w-1/4">
                  <label class="text-sm opacity-75">Tahun</label>
                  <v-select
                    :options="years"
                    :clearable="false"
                    v-model="year"
                  ></v-select>
                </div>
                <div class="vx-col w-full md:w-1/2">
                  <vs-button
                    class="mt-6"
                    color="primary"
                    icon-pack="feather"
                    icon="icon-file"
                    @click="exportData"
                    >Export Data</vs-button
                  >
                </div>
              </div>
            </div>
            <div v-else>No data. Select employee first.</div>
          </div>
        </vx-card>
      </div>

      <!-- Absent Chart Employee -->
      <div class="vx-col w-full md:w-1/3 mb-base">
        <vx-card
          :title="`Grafik tahun ${employee.select ? employee.year : '-'}`"
          :subtitle="`Pegawai: ${employee.select ? employee.select.name : '-'}`"
          style="height: 100%"
          class="mb-base"
        >
          <div v-if="load.pieChart">Loading...</div>
          <div v-else>
            <div v-if="employee.select" slot="no-body">
              <!-- CHART -->
              <vue-apex-charts
                type="pie"
                height="334"
                class="my-12"
                :options="employee.pie.options"
                :series="employee.pie.series"
                v-if="employee.pie.series.length > 0"
              />

              <!-- CHART DATA -->
              <ul class="mb-1">
                <li
                  v-for="(label, i) in employee.pie.options.labels"
                  :key="i"
                  class="
                    flex
                    justify-between
                    py-3
                    px-6
                    border
                    d-theme-border-grey-light
                    border-solid border-r-0 border-l-0 border-b-0
                  "
                >
                  <span class="flex items-center">
                    <span
                      class="inline-block h-3 w-3 rounded-full mr-2"
                      :style="`background: ${employee.pie.options.colors[i]}`"
                    ></span>
                    <span class="font-semibold">{{ label }}</span>
                  </span>
                  <span>{{ employee.pie.series[i] }}</span>
                </li>
              </ul>
            </div>
            <div v-else>No data. Select employee first.</div>
          </div>
        </vx-card>
      </div>
    </div>

    <!-- Employee Punctuality In Year -->
    <div class="vx-row">
      <div class="vx-col w-full md:w-1/1 lg:w-1/1">
        <vx-card
          :title="`Jumlah ketepatan waktu datang tahun ${
            employee.select ? employee.year : '-'
          }`"
          :subtitle="`Pegawai: ${employee.select ? employee.select.name : '-'}`"
          class="mb-base"
        >
          <div v-if="load.punctuality">Loading...</div>
          <div v-else>
            <div v-if="employee.select" slot="no-body" class="p-6 pb-0">
              <vue-apex-charts
                type="bar"
                height="266"
                :options="employee.punctuality.options"
                :series="employee.punctuality.series"
              />
            </div>
            <div v-else>No data. Select employee first.</div>
          </div>
        </vx-card>
      </div>
    </div>

    <!-- Employee Time Came -->
    <div class="vx-row">
      <div class="vx-col w-full md:w-1/1 lg:w-1/1">
        <vx-card
          :title="`Grafik waktu datang keseluruhan tahun ${
            employee.select ? employee.year : '-'
          }`"
          :subtitle="`Pegawai: ${employee.select ? employee.select.name : '-'}`"
        >
          <div v-if="load.timeCome">Loading...</div>
          <div v-else>
            <div v-if="employee.select" slot="no-body" class="p-6 pb-0">
              <vue-apex-charts
                type="line"
                height="266"
                :options="employee.timecame.options"
                :series="employee.timecame.series"
              />
            </div>
            <div v-else>No data. Select employee first.</div>
          </div>
        </vx-card>
      </div>
    </div>

    <!-- poup detail scan -->
    <vs-popup
      classContent="popup-example"
      :title="`Detail`"
      :active.sync="popupDetailScan"
    >
      <div v-if="detailScan.length > 0">
        <h6 class="mb-2">Alasan</h6>
        <p>{{ detailScan.map(scan => scan.alasan).join(', ') }}</p>
      </div>
      <!-- <div v-else-if="detailScan.time_come > '08:00:00'">
        <h6>Alasan</h6>
        <p>Kesiangan.</p>
      </div> -->
      <div v-else>
        <h6>Alasan</h6>
        <p>-</p>
      </div>
    </vs-popup>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import axios from "@/axios.js";
import { mapGetters } from "vuex";
import moment from "moment";
import { FileUploadService } from "v-file-upload";
import recipe from "@/recipe";

// Ramadhan Dates Config
import ramadhanDatesConfig from '@/config/ramadhanDatesConfig.js'

import StatisticsCardLine from "@/components/statistics-cards/StatisticsCardLine.vue";
import analyticsData from "./analyticsData.js";
import ChangeTimeDurationDropdown from "@/components/ChangeTimeDurationDropdown.vue";

const colors = {
  primary: "#00A651",
  danger: "#EF6F6C",
  success: "#22577A",
  warning: "#F5B841",
  dark: "#22577A",
};

export default {
  data() {
    return {
      load: {
        scans: false,
        pieChart: false,
        punctuality: false,
        timeCome: false,
      },

      month: {
        label: "",
        value: "",
      },
      months: [
        { label: "Januari", value: "01" },
        { label: "Februari", value: "02" },
        { label: "Maret", value: "03" },
        { label: "April", value: "04" },
        { label: "Mei", value: "05" },
        { label: "Juni", value: "06" },
        { label: "Juli", value: "07" },
        { label: "Agustus", value: "08" },
        { label: "September", value: "09" },
        { label: "Oktober", value: "10" },
        { label: "November", value: "11" },
        { label: "Desember", value: "12" },
      ],
      years: [],
      year: '',

      ramadhanDatesConfig: ramadhanDatesConfig,

      timeComeScanIds: [1, 3, 5, 7, 10],
      timeLeaveScanIds: [2, 4, 6, 8, 9, 11],

      loaded: false,
      url: "https://api-e-office.rshme.codes/api/v1/absents/upload",
      headers: {},
      filesUploaded: [],

      popupDetailScan: false,
      detailScan: {},
      isMounted: false,

      sql: "",
      redirect: window.location.href,

      notEmployees: [27505002, 17605001, 17905003],

      chartEmployeePieOptions: {
        labels: ["Tepat Waktu", "Telat", "Izin Telat", "Izin Pulang"],
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return val.toFixed(2) + "%";
          },
          dropShadow: {},
        },
        legend: { show: false },
        chart: {
          type: "pie",
          offsetY: 0,
          dropShadow: {
            enabled: false,
            blur: 5,
            left: 1,
            top: 1,
            opacity: 0.2,
          },
          toolbar: {
            show: false,
          },
          fontFamily: "Montserrat",
        },
        stroke: {
          width: 5,
        },
        colors: Object.values(colors),
        fill: {
          type: "gradient",
          gradient: {
            gradientToColors: Object.values(colors),
          },
        },
      },
      chartEmployeePie: {},
      test: false,
      position: {},
      positions: [
        {
          label: "All Position",
          value: 0,
        },
      ],

      // punctuality
      punctuality: {
        options: {
          chart: {
            stacked: true,
            fontFamily: "Montserrat",
          },
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: "55%",
            },
          },
          stroke: {
            show: true,
            width: 2,
            colors: ["transparent"],
          },
          xaxis: {
            categories: [],
          },
          colors: [],
        },
        series: [],
        filter: {
          start_date: moment(new Date())
            .subtract(new Date().getDate() - 1, "days")
            .format("yyyy-MM-DD"),
          end_date: moment(new Date()).format("yyyy-MM-DD"),
        },
      },

      // employee
      employee: {
        select: null,
        year: new Date().getFullYear(),
        scans: [],
        punctuality: {
          options: {},
          series: [],
        },
        pie: {
          options: {},
          series: [],
        },
        timecame: {
          options: {},
          series: [],
        },
      },
    };
  },
  watch: {
    position() {
      this.getPunctualityChart(this.position.value);
    },
  },
  components: {
    VueApexCharts,
    StatisticsCardLine,
    ChangeTimeDurationDropdown,
  },
  computed: {
    ...mapGetters({
      employees: "employee/getItems",
    }),
    employeesOption() {
      if (this.employees) {
        return this.employees.map((item) => {
          let result = {
            label: item.name,
            value: item.nik,
          };

          return result;
        });
      } else {
        return [];
      }
    },
    excep() {
      return [2015090001, 17605001, 49315029, 49918045];
    },
    user() {
      return this.$store.state.AppActiveUser;
    },
  },
  methods: {
    exportData() {
      window.open(
        `${recipe}/api/v1/absents/employee/scan/monthly/export/${this.employee.select.nik}/${this.month.value}/${this.year}`,
        "_blank"
      );
    },
    getCurrentTime() {
      var months = [
        "Januari",
        "Februari",
        "Maret",
        "April",
        "Mei",
        "Juni",
        "Juli",
        "Agustus",
        "September",
        "Oktober",
        "November",
        "Desember",
      ];
      var now = new Date();
      var thisMonth = months[now.getMonth()];
      var thisYear = now.getFullYear();
      var min = 2014;
      var max = thisYear;

      for (var i = max; i >= min; i--) {
        this.years.push(i);
      }

      this.month = {
        label: thisMonth,
        value: now.getMonth() + 1,
      };

      this.year = thisYear;
    },
    handleFile() {
      if (this.isMounted) {
        let sqlFile = this.$refs.sqlFile.files[0];
        let sqlSize = sqlFile.size;

        let formData = new FormData();
        let settings = {
          headers: {
            "content-type": "multipart/form-data",
          },
          onUploadProgress: (progressEvent) => {
            let percentage = (progressEvent.loaded / sqlSize) * 100;

            console.log("Upload " + percentage + "%");
          },
        };

        formData.append("sql", sqlFile, sqlFile.name);

        // show loading
        this.$vs.loading({
          type: "border",
          color: "primary",
          text: `Wait a minute, It's uploading file...`,
        });

        axios
          .post("/absents/upload", formData, settings)
          .then(({ data }) => {
            this.$swal({
              title: "Successful",
              text: data.message,
              icon: "success",
            });

            this.$refs.sqlFile.value = "";
          })
          .catch(({ response }) => console.log(response.data))
          .finally(() => {
            this.$vs.loading.close();
          });
      }
    },

    displayName(name) {
      let split = name.split(" ");

      if (split.length > 1) {
        if (split[0] == "Muhammad" || split[0] == "Muhamad") {
          return split[0][0] + ". " + split[1];
        } else {
          return split[0] + " " + split[1][0] + ".";
        }
      }

      return split[0];
    },
    loading() {
      this.loaded = false;

      this.$vs.loading({
        type: "border",
        color: "primary",
        text: `Wait a minute, It's getting data.`,
      });
    },
    detailEmployee(employee) {
      this.employee.select = employee;

      this.employeeScans();
    },
    employeeScans() {
      this.load.scans = true;
      this.load.pieChart = true;
      this.load.punctuality = true;
      this.load.timeCome = true;

      // List scans of a Employee in Year
      let promise1 = axios.get(
        `/absents/employee/scan/${this.employee.select.nik}/${this.employee.year}`
      );

      // Pie chart of a Employee in Year
      let promise2 = axios.get(
        `/absents/employee/pie/${this.employee.select.nik}/${this.employee.year}`
      );

      // Punctuality of a Employee in Year
      let promise3 = axios.get(
        `/absents/employee/punctuality/${this.employee.select.nik}/${this.employee.year}`
      );

      // Time come of a Employee in Year
      let promise4 = axios.get(
        `/absents/employee/time-came/${this.employee.select.nik}/${this.employee.year}`
      );

      promise1.then((response) => {
        this.load.scans = false;
        this.employee.scans = response.data.map((item) => {
          item.fri = moment(new Date(item.date)).format("ddd") == "Fri";
          item.date = moment(new Date(item.date)).format("DD/MM/yyyy");
          item.chipCome = false;
          item.chipLeave = false;

          if (item.time_come > "08:00:00") {
            item.chipCome = "danger";
          }

          let ramadhanDate = this.ramadhanDatesConfig[this.employee.year]

          if (
            ramadhanDate &&
            moment(item.date, 'DD/MM/yyyy').isBetween(moment(ramadhanDate.from, 'DD/MM/yyyy'), moment(ramadhanDate.to, 'DD/MM/yyyy'), undefined, '[]')
          ) {
            if(item.time_leave < "16:29:59") {
              item.chipLeave = "danger";
            }
          } else {
            if(item.time_leave < "16:59:59") {
              item.chipLeave = "danger";
            }
          }

          if(item.all_ex_scan.some(item => this.timeComeScanIds.includes(item.scan_id))) {
            item.chipCome = "primary";
          }
          if(item.all_ex_scan.some(item => this.timeLeaveScanIds.includes(item.scan_id))){
            item.chipLeave = "primary";
          }

          return item;
        });
      });

      promise2.then((response) => {
        this.load.pieChart = false;
        this.employee.pie.options = {
          labels: response.data.labels,
          dataLabels: {
            enabled: true,
            formatter: function (val) {
              return val.toFixed(2) + "%";
            },
            dropShadow: {},
          },
          legend: { show: false },
          chart: {
            type: "pie",
            offsetY: 0,
            dropShadow: {
              enabled: false,
              blur: 5,
              left: 1,
              top: 1,
              opacity: 0.2,
            },
            toolbar: {
              show: false,
            },
            fontFamily: "Montserrat",
          },
          stroke: {
            width: 5,
          },
          colors: Object.values(colors),
        };
        this.employee.pie.series = response.data.series;
      });

      promise3.then((response) => {
        this.load.punctuality = false;
        this.employee.punctuality.options = {
          chart: {
            stacked: true,
            fontFamily: "Montserrat",
          },
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: "55%",
            },
          },
          stroke: {
            show: true,
            width: 2,
            colors: ["transparent"],
          },
          xaxis: {
            categories: response.data.categories,
          },
          colors: Object.values(colors),
        };
        this.employee.punctuality.series = response.data.series;
      });

      promise4.then((response) => {
        this.load.timeCome = false;
        this.employee.timecame.options = {
          chart: {
            dropShadow: {
              enabled: true,
              top: 5,
              left: 0,
              blur: 4,
              opacity: 0.1,
            },
            zoom: {
              enabled: true,
              type: "x",
              autoScaleYaxis: false,
              zoomedArea: {
                fill: {
                  color: "#90CAF9",
                  opacity: 0.4,
                },
                stroke: {
                  color: "#0D47A1",
                  opacity: 0.4,
                  width: 1,
                },
              },
            },
            fontFamily: "Montserrat",
          },
          xaxis: {
            categories: response.data.categories.map((category) =>
              moment(new Date(category)).format("DD-MM-yyyy")
            ),
            tickAmount: 6,
            labels: {
              rotate: 0,
            },
          },
          yaxis: {
            show: false,
          },
          tooltip: {
            enabled: true,
            y: {
              formatter: (value) => moment(new Date(value)).format("HH:mm:ss"),
              title: {
                formatter: (seriesName) => seriesName,
              },
            },
          },
          colors: Object.values(colors),
          stroke: {
            curve: "smooth",
            dashArray: [0, 8],
            width: [4, 2],
          },
          legend: {
            show: false,
          },
        };
        this.employee.timecame.series = response.data.series.map((series) => {
          series.data = series.data.map((data, i) =>
            new Date(`2020-01-01 ${data}`).getTime()
          );
          return series;
        });
      });
    },

    showDetailScan(scan, scanTime) {
      this.popupDetailScan = true;

      if (scanTime === 'time_come') {
        this.detailScan = scan.all_ex_scan.filter(scan => this.timeComeScanIds.includes(scan.scan_id))
      } else if (scanTime === 'time_leave') {
        this.detailScan = scan.all_ex_scan.filter(scan => this.timeLeaveScanIds.includes(scan.scan_id))
      }
    },

    getPunctualityChart(position = false) {
      let url = `/absents/punctuality-chart/${this.punctuality.filter.start_date}/${this.punctuality.filter.end_date}`;
      if (position) {
        url += `?position=${position}`;
      }

      console.log(url);

      // Punctuality of All Employees
      axios
        .get(url)
        .then((response) => {
          this.punctuality.options = {
            chart: {
              stacked: true,
              fontFamily: "Montserrat",
            },
            plotOptions: {
              bar: {
                horizontal: false,
                columnWidth: "55%",
              },
            },
            stroke: {
              show: true,
              width: 2,
              colors: ["transparent"],
            },
            xaxis: {
              categories: response.data.categories.map((emp) =>
                this.$options.filters.capitalize(emp)
              ),
            },
            colors: Object.values(colors),
          };

          this.punctuality.series = response.data.series;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.onLoaded();
        });
    },
    thumbUrl(file) {
      return file.myThumbUrlProperty;
    },
    onFileChange(file) {
      // Handle files like:
      this.fileUploaded = file;
      this.mySaveMethod(file);
    },

    mySaveMethod(file) {
      let fileUpload = new FileUploadService(
        this.url,
        this.headers,
        this.onProgress
      );
      fileUpload
        .upload(file, {})
        .then((e) => {
          // Handle success
          this.$swal({
            title: "Successful",
            text: "File has been uploaded",
            icon: "success",
          }).then(() => {
            //
          });
        })
        .catch((e) => {
          console.log(e);
          alert("Server error");
          // Handle error
        });
    },

    onProgress(event) {
      // Handdle the progress
    },

    onLoaded() {
      this.loaded = true;
      this.$vs.loading.close();
    },

    onSubmit() {
      this.isSubmit = true;
    },
  },

  updated () {
    // if (typeof(Event) === 'function') {
    //   // modern browsers
    //   window.dispatchEvent(new Event('resize'));
    // } else {
    //   // for IE and other old browsers
    //   // causes deprecation warning on modern browsers
    //   var evt = window.document.createEvent('UIEvents');
    //   evt.initUIEvent('resize', true, false, window, 0);
    //   window.dispatchEvent(evt);
    // }
  },

  mounted() {
    this.isMounted = true;

    this.$nextTick(() => {
      this.position = {
        label: "All Position",
        value: 0,
      };
    });
  },

  created() {
    this.getPunctualityChart();
    this.getCurrentTime();

    if (this.excep.includes(this.user.employee.nik) == false) {
      this.detailEmployee(this.user.employee);
    }
    this.loading();

    axios
      .get("/positions")
      .then((response) => {
        let positions = response.data.values.map((item) => {
          return { label: item.name, value: item.id };
        });
        this.positions = this.positions.concat(positions);
      })
      .catch((error) => {
        console.log(error);
      });

    // Attendance
    this.$http
      .get("/api/widgets/analytics/attendance")
      .then((response) => {
        this.chartEmployeePie = response.data;
      })
      .catch((error) => {
        console.log(error);
      });

    this.$store.dispatch("employee/fetchItems");
  },
};
</script>

<style lang="scss" scoped>
#demo-card-analytics {
  .tasks-today-container {
    .tasks-today__task {
      transition: background 0.15s ease-out;
      .tasks-today__actions {
        display: none;
      }

      &:hover {
        background: rgba(var(--vs-primary), 0.04);
        .tasks-today__actions {
          display: flex;
        }
      }
    }
  }
}

.vs-chip-primary {
  background: rgba(var(--vs-primary), 0.15);
  color: rgba(var(--vs-primary), 1) !important;
  font-weight: 500;
}
.vs-chip-success {
  background: rgba(var(--vs-success), 0.15);
  color: rgba(var(--vs-success), 1) !important;
  font-weight: 500;
}
.vs-chip-warning {
  background: rgba(var(--vs-warning), 0.15);
  color: rgba(var(--vs-warning), 1) !important;
  font-weight: 500;
}
.vs-chip-danger {
  background: rgba(var(--vs-danger), 0.15);
  color: rgba(var(--vs-danger), 1) !important;
  font-weight: 500;
}

.employee-item {
  width: 100%;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 6px;
  margin-top: 12px;
  font-size: 12px;
  transition: 0.1s;
  cursor: pointer;
}
.employee-item.active {
  background: linear-gradient(
    118deg,
    rgba(var(--vs-primary), 1),
    rgba(var(--vs-primary), 0.7)
  );
  color: #fff;
  font-weight: 500;
  border-color: rgba(var(--vs-primary), 0.7);
}
.employee-item:not(.active):hover {
  background: rgba(0, 0, 0, 0.1);
}
.con-vs-avatar {
  margin: 0px !important;
  margin-right: 1rem !important;
}
</style>
